import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import ImageHeader from "components/ui/base/ImageHeader";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import FullWidthImage from "components/ui/base/FullWidthImage";
import VideoLiveChatBox from "components/ui/extended/VideoLiveChatBox";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import { graphql } from "gatsby";
import Customers from "components/ui/extended/Customers";
import MobileBox from "components/ui/extended/MobileBox";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import CustomerServiceFeatures from "components/ui/extended/CustomerServiceFeatures";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.live-chat-for-website.seo.title")}
        description={t("pages.features.live-chat-for-website.seo.description")}
        image={{
          relativePath: "meta/free-live-chat-for-website.jpg",
          alt: "Free Live Chat for Website ",
        }}
      />

      <ImageHeader
        title={t("pages.features.live-chat-for-website.title")}
        description={t("pages.features.live-chat-for-website.description")}
        image={{
          relativePath: "pages/features/live-chat-for-website/header.png",
          alt: "Live Chat Plugin for Shopify",
        }}
        cta={{
          show: true,
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat for Website",
            path: "/features/live-chat-for-website/",
          },
        ]}
      />

      <VideoLiveChatBox />

      <FullWidthImage
        title={t("pages.features.live-chat-for-website.support-channels.title")}
        description={t(
          "pages.features.live-chat-for-website.support-channels.description"
        )}
        cta={{
          link: "/features/conversation-inbox/",
          text: t("pages.features.live-chat-for-website.support-channels.cta"),
        }}
        image={{
          relativePath:
            "pages/features/live-chat-for-website/support-channels.png",
          alt: t("pages.features.live-chat-for-website.support-channels.title"),
        }}
      />

      <ImageWithDescription
        image={{
          relativePath: "pages/features/live-chat-for-website/autoreply.png",
          alt: t("pages.features.live-chat-for-website.autoreply.title"),
          position: "right",
          fullWidth: true,
        }}
        title={t("pages.features.live-chat-for-website.autoreply.title")}
        description={t(
          "pages.features.live-chat-for-website.autoreply.description"
        )}
        cta={{
          link: "/features/help-center/",
          text: t("pages.features.live-chat-for-website.autoreply.cta"),
        }}
      />

      <TestimonialWithImageIrene />

      <MobileBox />

      <HorizontalStoriesMenu
        title={t("pages.features.live-chat-for-website.canned-responses.title")}
        paragraph={t(
          "pages.features.live-chat-for-website.canned-responses.paragraph"
        )}
        storiesPosition={"right"}
        elements={[
          {
            title: t(
              "pages.features.live-chat-for-website.canned-responses.search-click-send.title"
            ),
            description: t(
              "pages.features.live-chat-for-website.canned-responses.search-click-send.description"
            ),
            story: {
              relativePath: "pages/features/live-chat/canned-responses.mov",
            },
          },
          {
            title: t(
              "pages.features.live-chat-for-website.canned-responses.customize.title"
            ),
            description: t(
              "pages.features.live-chat-for-website.canned-responses.customize.description"
            ),
            story: {
              relativePath:
                "pages/features/live-chat/canned-response-replace-property.mov",
            },
          },
        ]}
      />

      <CustomerServiceFeatures
        customTitle={t("pages.features.live-chat-for-website.features")}
      />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
